


import React, { Component } from "react";
// import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/Navbar";
import Section from "./Components/Section";
// import dummyText from "./DummyText";
// import Card from "react-bootstrap/Card";
import Content from "./Content";
// import CardGroup from "react-bootstrap/CardGroup";
// import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn } from 'mdb-react-ui-kit';
// import nyskyline from "./nyskyline.jpeg";
// import bowery from "./bowery.png";
class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar />
        <Section
          title="Elsa's Birthday Surprise"
          subtitle={""}
          dark={false}
          id="home"
          num="0" />
        {/* <Section
          title="Destination"
          subtitle={" "}
          dark={true}
          id="section1"
          num="1"
        /> */}
        {/* <div>
            <Card 
              bg={'primary'}
              style={{ width: '18rem' }}
              >
                <Card.Img variant="top" src={nyskyline} />
                <Card.Body>
                    <Card.Title>City</Card.Title>
                    <Card.Text>
                        
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card
              bg={'secondary'}
             style={{ width: '18rem' }}>
              <Card.Img variant="top" src={bowery} />
              <Card.Body>
                <Card.Title>Neighborhood</Card.Title>
              </Card.Body>                
            </Card>
            <Card
              bg={'success'}
              style={{ width: '18rem'}}
              >
              <Card.Body>
                <Card.Title>Dates</Card.Title>
                <Card.Text>February 11 - February 13, 2021</Card.Text>
              </Card.Body>  
            </Card>
        </div> */}

        {/* <Section
          title="Accomodations"
          subtitle={dummyText}
          dark={false}
          id="section2"
          num="2"
        >
        </Section>
        <Section
          title="Activities"
          subtitle={dummyText}
          dark={true}
          id="section3"
          num="3"
        />
        <Section
          title="Bonus"
          subtitle={dummyText}
          dark={false}
          id="section4"
          num="4"
        /> */}
        <Content />
        <div className="background1">
          <p> </p>
        </div>
      </div>
    );
  }
}

export default App;
