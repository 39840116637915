import React, { Component } from "react";
import { Link } from "react-scroll"; 
import sleeping from "../sleeping.png";

export default class Navbar extends Component {
  render() {
    return (
      <nav className="nav" id="navbar">
        <div className="nav-content">
          <img
            src={sleeping}
            className="nav-logo"
            alt="Logo."
            onClick={this.scrollToTop}
          />
          <ul className="nav-items">
            <li className="nav-item">
                <Link activeClass="active"
                    to="destination"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}>Destination</Link></li>
            <li className="nav-item">
                <Link activeClass="active"
                    to="accomodations"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}>Accomodations</Link></li>
            <li className="nav-item">
                <Link activeClass="active"
                    to="activities"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}>Activities</Link></li>
            <li className="nav-item">
                <Link activeClass="active"
                    to="bonus"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}>Bonus</Link></li>
          </ul>
        </div>
      </nav>
    );
  }
}