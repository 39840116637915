import React from "react";
// import { Accordion, Card } from "react-bootstrap";
import { Card } from "react-bootstrap";
import nyskyline from "./nyskyline.jpeg";
import bowery from "./bowery.png";
import calendar from "./calendar.png";
import citizenmview from "./citizenmview.jpeg";
import nyfw from "./NYFW.jpeg";

function App() {
  return (
    <div className="content">
      <h1 className="content-title"  id="destination">
        DESTINATION
      </h1>
      <p className="content-paragraph">
        I think you'll be excited to know...
      </p>
      <div className="projects-container">
        <Card className="content-card">
        <Card.Img variant="top" src={nyskyline} />
          <Card.Body>
            <Card.Title>NEW YORK CITY</Card.Title>
            <Card.Text>
              We'll be going to New York City together!
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="content-card grade-calc">
            <Card.Img variant="top" src={bowery} />
          <Card.Body>
            <Card.Title>Neighborhood</Card.Title>
            <Card.Text>
              We will be staying downtown in the Bowery, just east of SOHO and Little Italy.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="content-card">
        <Card.Img variant="top" src={calendar} />
          <Card.Body>
            <Card.Title>Dates</Card.Title>
            <Card.Text>
              We will be going February 11 - February 13. Don't worry, I've already cleared with Rachel that she can cover for you this weekend :)
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      {/* <div className="space">
          <p>...</p>
      </div> */}
      <h1 className="content-title" id="accomodations">
        Accomodations
      </h1>
      <div className="projects-container">
        <Card className="content-card">
            <Card.Img variant="top" src={citizenmview} />
            <Card.Body>
                <Card.Title>Hotel</Card.Title>
                <Card.Text>
                    We will be staying in the citizenM bowery hotel, with skyline views.
                </Card.Text>
                <Card.Link href="https://www.citizenm.com/hotels/united-states/new-york/new-york-bowery-hotel">
                    Our Hotel
                </Card.Link>
            </Card.Body>
        </Card>
      </div>
      {/* <div className="space">
          <p>...</p>
      </div> */}
      <h1 className="content-title" id="activities">
          Activities
      </h1>
      <div className="projects-container">
        <Card>
            <Card.Img variant="top" src={nyfw}></Card.Img>
            <Card.Body>
                <Card.Title>Activities</Card.Title>
                <Card.Text>
                    The reason we will be going to New York is... NEW YORK FASHION WEEK!
                </Card.Text>
            </Card.Body>
        </Card>
      </div>
      {/* <div className="space">
          <p>...</p>
      </div> */}
      <h1 className="content-title" id="bonus">
        Bonus!
      </h1>
      <h2>
          You now own this domain! I will help you design your personal website :) 
          or you can design with another service if I am not as good as I think I am.
      </h2>
      <div></div>
    </div>
  );
}

export default App;