import React from "react";
// import Card from 'react-bootstrap/Card';
// import { CardGroup } from "react-bootstrap";

export default function Section({ title, subtitle, dark, id, num }) {
        return (
            <div className={"section" + num}>
              <div className={"section-content" + num} id={id}>
                <h1>{title}</h1>
                <h3>{subtitle}</h3>
              </div>
            </div>
          );
  
}